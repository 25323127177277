import React from 'react';

export const AdSenseGoogleAd = () => {
	React.useEffect(() => {
		if(window.adsbygoogle){
			window.adsbygoogle.push({});
		}
		else {
			window.adsbygoogle = [];
			window.adsbygoogle.push({})
		}
	
	}, [])

	return (
			<ins className="adsbygoogle"
				style={{display:"block"}}
				data-ad-client="ca-pub-1371660541011692"
				data-ad-slot="6153451051"
				data-ad-format="auto"
				data-full-width-responsive="true"></ins>
	);
}